export const center_component = 'flex items-center justify-center';

// GITHUB RELATED CONSTANTS
const github_username = 'trinnovation-incorporate';
const repo_name = 'blogs_repo';
const raw_github_base_url = 'https://raw.githubusercontent.com';
const repo_branch = 'main';

export const HTML_BASE_URL = `${raw_github_base_url}/${github_username}/${repo_name}/${repo_branch}/`;

export const JSON_FILE_PATH = HTML_BASE_URL + 'metadata.json';

export const defaultNavData = [
	{ title: 'Services', link: '/service', style: 'text-gray-700' },
	{ title: 'Contact', link: '/contact', style: 'text-gray-700' },
	{ title: 'About', link: '/about', style: 'text-gray-700' },
	{ title: 'Blogs', link: '/blogs', style: 'text-blue-500' },
	{ title: 'Jobs', link: '/jobs', style: 'text-gray-700' },
];
